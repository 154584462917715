import PropTypes from "prop-types";
import React, { Fragment } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import FooterOne from "../wrappers/footer/FooterOne";
import NavbarOne from "../wrappers/header/NavbarOne";
const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
  location,
}) => {
  // const {pathname} = location;
  const pathname = window.location.href;
  // console.log("pathname", window.location.href , !pathname.indexOf("catalouge"));

  return (
    <Fragment>
      
        <>
          {/* <HeaderOne
            layout={headerContainerClass}
            top={headerTop}
            headerPaddingClass={headerPaddingClass}
            headerPositionClass={headerPositionClass}
          /> */}
          <NavbarOne/>
          {children}
          <FooterOne
            backgroundColorClass="bg-gray"
            spaceTopClass=""
            spaceBottomClass=""
          />
        </>
     
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default LayoutOne;
