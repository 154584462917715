import {
  Badge,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  MenuItem,
  Menu,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import AmyraaLogo from "../../assets/img/FooterLogo.png";
import Apilib from "../../utils/api";
import { search } from "../../redux/actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import TemporaryDrawer from "./drawer";
import setUser from "../../redux/actions/userActions";
import { useToasts } from "react-toast-notifications";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import profileIcon from "./profileIcon.png";

const useStyles = makeStyles(() => ({
  customBadge: {
    backgroundColor: "rgba(31, 120, 114, 1)", // Badge background color
    color: "white", // Badge text color
    width: "20px", // Set width
    height: "20px", // Set height
    borderRadius: "50%", // Circular shape
    fontSize: "12px", // Adjust font size (optional)
  },
}));

const NavbarOne = () => {
  const classes = useStyles();

  const [searchEnable, setSearchDisable] = useState(0);
  const { addToast } = useToasts();

  const userDetailsHome = useSelector((state) => state?.userDetails?.user);

  const wishlistItems = useSelector((state) => state.wishlist.wishlistItems);

  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const searchText = useSelector((state) => state.allProducts.search);
  const userInfo = useSelector((state) => state);
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const API = new Apilib();
  const headerRef = useRef(null); // Create a ref for the header

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(userInfo?.userInformation?.user, "ChMainChecker");
  const [authenticatedUser, setAuthenticatedUser] = useState(false);

  const cartItems = useSelector((state) => state.cart.cartItems);

  const isMobile = useMediaQuery("(max-width: 768px)"); // Media query to detect mobile view

  const handleClick = (e) => {
    const cart = e.currentTarget.nextSibling;
    cart.classList.toggle("active");
    if (cartItems.length <= 0) {
      setTimeout(() => {
        cart.classList.remove("active");
      }, 3000);
    }
  };

  function userLogout(e) {
    e.preventDefault();
    localStorage.removeItem("amyra_email");
    dispatch(setUser(null));
    setAuthenticatedUser(false);
    addToast("User logged out successfully", {
      appearance: "success",
      autoDismiss: true,
    });
    history.push("/login");
  }

  useEffect(() => {
    const header = headerRef.current;
    if (header) {
      const handleScroll = () => {
        setScroll(window.scrollY);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    FetchUserDetails();
    //eslint-disable-next-line
  }, []);

  function FetchUserDetails() {
    setLoading(true);
    API.GetUserDetails(localStorage?.amyra_email)
      .then((res) => {
        // console.log("user Details", res.data);
        let userInfo = { ...res.data[0] };
        setUserDetails(userInfo);
        setLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        setLoading(false);
      });
  }

  const handleSearch = (e) => {
    dispatch(search(e));
  };

  const handleSearchSubmit = () => {
    if (searchText.trim() !== "") {
      history.push({
        pathname: "/searchResult",
        search: "?value=" + searchText,
        state: { detail: searchText },
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  return (
    <Box
      ref={headerRef} // Attach ref to the header element
      style={{
        width: "100%",
        position: "sticky",
        top: "0",
        height: "6rem",
        backgroundColor: "white",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        zIndex: "10",
        padding: isMobile ? "0rem 1rem" : "0rem 2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* Logo Section */}
      <Box style={{ width: "10rem", height: "4rem" }}>
        {/* <Link to="/"> {/* Link to homepage */}
        {/* <img src={AmyraaLogo} alt="" width="100%" /> */}
        {/* </Link> */}

        {!userDetails?.USERIMG ? (
          <Link to={process.env.PUBLIC_URL + "/"}>
            <img src={AmyraaLogo} alt="" width="100%" />
          </Link>
        ) : (
          <Link to={process.env.PUBLIC_URL + "/"}>
            <div>
              <img
                src={userDetails?.USERIMG}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = AmyraaLogo;
                }}
                style={{ height: 75 }}
                alt="Business Logo"
              />
            </div>
          </Link>
        )}
      </Box>

      {isMobile ? (
        <>
          <Box>
            <TemporaryDrawer />
          </Box>
        </>
      ) : (
        <>
          <Box
            style={{ height: "5rem", display: "flex", alignItems: "center" }}
          >
            <Box
              style={{
                display: "flex",
                gap: "1.4rem",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(128, 132, 136, 1)",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Home
                </Typography>
              </Link>
              <Link to="/products" style={{ textDecoration: "none" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(128, 132, 136, 1)",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                >
                  Shop
                </Typography>
              </Link>

              <Link to="/Collection" style={{ textDecoration: "none" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(128, 132, 136, 1)",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                >
                  Collection
                </Typography>
              </Link>

              <Link to="/about" style={{ textDecoration: "none" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(128, 132, 136, 1)",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                >
                  About us
                </Typography>
              </Link>
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(128, 132, 136, 1)",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Contact us
                </Typography>
              </Link>

              {/* Search Input */}
              <TextField
                placeholder="Search Products"
                variant="outlined"
                size="small"
                style={{ width: "14rem", marginRight: "1rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "rgba(128, 132, 136, 1)" }} />
                    </InputAdornment>
                  ),
                }}
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                onKeyPress={handleKeyPress}
              />

              {userDetailsHome ? (
                <>
                  <Box>
                    <Link
                      className="icon-cart"
                      to={process.env.PUBLIC_URL + "/wishlist"}
                    >
                      <Badge
                        badgeContent={wishlistItems.length}
                        classes={{ badge: classes.customBadge }}
                      >
                        <FavoriteBorderOutlinedIcon
                          style={{
                            color: "rgba(31, 120, 114, 1)",
                            cursor: "pointer",
                          }}
                        />
                      </Badge>
                    </Link>
                  </Box>

                  <Box>
                    <Link
                      className="icon-cart"
                      to={process.env.PUBLIC_URL + "/cart"}
                    >
                      <Badge
                        badgeContent={cartItems.length}
                        classes={{ badge: classes.customBadge }}
                      >
                        <ShoppingCartOutlinedIcon
                          style={{
                            color: "rgba(31, 120, 114, 1)",
                            cursor: "pointer",
                          }}
                        />
                      </Badge>
                    </Link>
                  </Box>
                  {/* user button */}

                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleProfileClick}
                      style={{
                        color: "#2D2C2C",
                        fontFamily: "Montserrat",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "capitalize",
                      }}
                    >
                      Hii, {userDetails?.FNAME}{" "}
                      <Box
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginLeft: ".8rem",
                        }}
                      >
                        <img src={profileIcon} width={"100%"} height={"100%"} />
                      </Box>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          history.push("/account");
                        }}
                        style={{ color: "#2D2C2C", fontFamily: "Montserrat" }}
                      >
                        My Account
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          history.push("/MyOrders");
                        }}
                        style={{ color: "#2D2C2C", fontFamily: "Montserrat" }}
                      >
                        My Orders
                      </MenuItem>
                      <MenuItem
                        onClick={userLogout}
                        style={{ color: "#E95F70", fontFamily: "Montserrat" }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              ) : (
                <>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <Button
                      style={{
                        backgroundColor: "rgba(31, 120, 114, 1)",
                        color: "white",
                        textTransform: "capitalize",
                        fontFamily: "Montserrat",
                        width: "10rem",
                        fontWeight: "500",
                      }}
                    >
                      Login/Register
                    </Button>
                  </Link>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NavbarOne;
