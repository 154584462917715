import { ActionTypes } from "../constants/action-type";

const initialState = {
  cartItems: [],
};
export const CartReducer = (state = initialState, { type, payload }) => {
  // console.log("cart payload", type, payload);
  switch (type) {
    case ActionTypes.ADD_TO_CART:
      return { ...state, cartItems: payload };
    default:
      return state;
  }
};
