import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductListingSingle from "./ProductListingSingle";
import ApiLib from "../../utils/api";
import setProducts from "../../redux/actions/productAction";
import Loader from "../../helpers/Loader";
import { useToasts } from "react-toast-notifications";
import getWishlist from "../../redux/actions/wishlistActions";
import SharePriceModal from "./SharePriceModal";
import mixpanel from "mixpanel-browser";
import { useParams, useLocation } from "react-router-dom";

const viewAllObject = {
  "fast-moving": "GetFastMoving",
  "new-arrival": "GetNewArrival",
  "most-popular": "GetMostPopular",
};

const ProductListingGrid = ({
  addToWishlist,
  selectedProducts,
  selectedProductsArr,
  checkAll,
  productsLength,
  setProductsLength,
}) => {
  const [allProducts, setAllProducts] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const API = new ApiLib();
  const filters_top = useSelector((state) => state.allProducts.filters_top);
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { fetchKey } = useParams();
  const location = useLocation();

  const [modalOpen, setmodalOpen] = useState(false);
  const [shareData, setShareData] = useState(null);

  const [showUserModeModal, setShowUserModeModal] = useState(false);

  const pathname = location.pathname.split("/")[2];

  const showMorePlp = () => {
    mixpanel.track("Shop_PLP_ShowMore");
  };

  useEffect(() => {
    setPageCount(1);
    FetchAllProducts(1, productsLength);
    //eslint-disable-next-line
  }, [filters_top, productsLength]);

  useEffect(() => {
    if (pageCount > 1) {
      FetchMoreProducts(pageCount);
    }
    //eslint-disable-next-line
  }, [pageCount]);

  function FetchMoreProducts(count) {
    setLoading(true);
    // {color: Array(0), plating: Array(0), lowerPrice: '', upperPrice: '', sortby: 'CREATEDTIME desc',upperPrice : ""}
    API.FetchProducts(count, filters_top, userDetails, 30)
      .then((res) => {
        const products = res.data;
        const localHide = products && products.length > 0 ? false : true;
        setAllProducts([...allProducts, ...products]);
        dispatch(setProducts([...allProducts, ...products]));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  function FetchAllProducts(count, noOfProducts) {
    setLoading(true);

    API.FetchProducts(count, filters_top, userDetails, noOfProducts)
      .then((res) => {
        console.log("fetch all products working");
        const products = res.data;
        setAllProducts([...products]);
        dispatch(setProducts([...products]));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }
  useEffect(() => {
    if (userDetails?.user?.ID) {
      FetchWishlist();
    }
    //eslint-disable-next-line
  }, []);

  const FetchWishlist = () => {
    setLoading(true);
    API.GetUserWishlist(userDetails.user.ID)
      .then((res) => {
        console.log("wishlist==>", res.data);
        setWishlist(res.data);
        dispatch(getWishlist(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const DeleteFromWishlist = (DCODE) => {
    setLoading(true);
    API.DeleteWishlist(userDetails.user.ID, DCODE)
      .then(async (res) => {
        console.log(res);
        addToast("Item removed from wishlist", {
          appearance: "success",
          autoDismiss: true,
        });
        await FetchWishlist();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const AddToWishlist = (product) => {
    if (!userDetails?.user?.ID) {
      addToast("Kindly login to add item to wishlist ", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    API.AddToWishlist(userDetails.user.ID, product.DCODE)
      .then((res) => {
        setLoading(false);
        console.log(res);
        //
        mixpanel.track("AddToWishList", {
          DesignId: JSON.stringify(product.DCODE),
        }); // MixPannel ka code
        //
        addToast("Item added to wishlist successfully ", {
          appearance: "success",
          autoDismiss: true,
        });
        FetchWishlist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  console.log("Loading => ", loading);
  console.log("Loading Products => ", allProducts);

  return (
    <Fragment>
      <SharePriceModal
        onClose={() => setShowUserModeModal(false)}
        show={showUserModeModal}
        data={shareData}
      />

      {loading ? (
        <Loader />
      ) : allProducts.length > 0 ? (
        allProducts.map((product) => {
          return (
            <ProductListingSingle
              setOpen={setShowUserModeModal}
              setdata={setShareData}
              checkAll={checkAll}
              product={product}
              selectedProducts={selectedProducts}
              selectedProductsArr={selectedProductsArr}
              wishlistPrds={wishlist}
              addwishlist={(product) => AddToWishlist(product)}
              deletewishlist={(DCODE) => DeleteFromWishlist(DCODE)}
            />
          );
        })
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {/* No Products Available */}
          <Loader />
        </div>
      )}

      {loading ? (
        <Loader />
      ) : allProducts.length >= pageCount * 30 ? (
        <div className=" col-12 text-center ">
          <button
            onClick={() => {
              showMorePlp();
              setPageCount(pageCount + 1);
            }}
            className=" ml-auto mr-auto Register-btn"
            style={{ background: "#14716b", color: "white", border: "none" }}
          >
            Show more
          </button>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ProductListingGrid;
