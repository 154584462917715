import { Box, Typography } from '@material-ui/core'
import React from 'react'
import FooterLogo from '../../assets/img/FooterLogo.png'
import { Link, useHistory } from "react-router-dom";

import Appstorelogo from '../../assets/img/amyraHome/FooterAppStoreIcon.png'
import PlayStore from '../../assets/img/amyraHome/FooterGplay.png'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import InstagramIcon from '../../assets/img/amyraHome/Instagram Icon.png'
import YoutubeIcon from '../../assets/img/amyraHome/YoutubeIcon.png'



const FooterOne = () => {

  const isMobile = useMediaQuery('(max-width:768px)'); // For mobile view detection



  return (
    <Box style={{
    padding:"1rem 1rem 0rem 1rem",
    backgroundColor:"rgba(241, 255, 254, 1)",
     width:'100%', 
    }}>
    <Box style={{
      width:"96%",
      margin:"0 auto",
      marginTop:"2rem",
      height: isMobile ? "36rem" : "18rem", // Conditionally set height for mobile view
      display:'flex',
      justifyContent:"space-between",
      flexWrap:"wrap",

    }}>
    <Box sx={{
      width: isMobile ? "100%" : "12rem", // Conditionally set height for mobile view
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
    }} >
     <Box sx={{
      width:"10rem",
     }} >
      <img src={FooterLogo} alt="" width="100%" />
     </Box>

     <Box style={{
      display:"flex",
      width:"40%",
      justifyContent:"space-between",
      margin:"0.6rem auto",
    }} >

    <Box style={{
      width:"2rem",
    }} >
      <img src={YoutubeIcon} alt="" width="100%" />
    </Box>

    <Box style={{
      width:"2rem",
    }} >
      <img src={InstagramIcon} alt="" width="100%" />
    </Box>

    </Box>

    </Box>

    <Box style={{
      width:'20rem',
    }} >
<Typography style={{
  color:"rgba(31, 120, 114, 1)",
  fontWeight:"700",
  fontFamily:"Montserrat",
  fontSize:"1.2rem",
  marginBottom:"0.5rem",
}} >Quick Links</Typography>
<Box style={{
display:"flex",
justifyContent:"space-between",
width:"100%"
}} >
  <Box style={{
    width:"30%",
  }} >

<Link to="/products" style={{ textDecoration: 'none' }}>

<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",
}} >Shop</Typography>
</Link>



<Link to="/Collection" style={{ textDecoration: 'none' }}>
<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",

}}   >Collection</Typography>
</Link>




<Link to="/contact" style={{ textDecoration: 'none' }}>

<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",

}}  >Contact Us</Typography>
</Link>

<Link to="/about" style={{ textDecoration: 'none' }}>
<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",
}}  >About Us</Typography>
</Link>




<Link to="/bloglisting" style={{ textDecoration: 'none' }}>
<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",
}}>Blogs</Typography>
</Link>



  </Box>
  <Box style={{
    width:"70%",
  }}  >


<Link to="/FAQ" style={{ textDecoration: 'none' }}>
<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",

}}  >FAQ’s</Typography>
</Link>
 


 
 <Link to="/Shipping" style={{ textDecoration: 'none' }}>
 <Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",

}}  >Shipping</Typography>
 </Link>




<Link to="/Return_Refund_Policy" style={{ textDecoration: 'none' }}>
<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",

}}  >Return and Refund Policy</Typography>

</Link>


<Link to="/Privacy_Policy" style={{ textDecoration: 'none' }}>
<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",

}}  >Privacy Policy</Typography>
</Link>



<Link to="/Terms_and_Conditions" style={{ textDecoration: 'none' }}>
<Typography style={{
  fontWeight:"500",
  fontFamily:"Montserrat",
  color:"rgba(95, 96, 96, 1)",
  fontSize:"1rem",
  marginTop:"0.5rem",

}}  >Terms & Conditions</Typography>
</Link>


  </Box>
</Box>

    </Box>

    <Box sx={{
      width:'18rem',
    }} >
<Typography style={{
  color:"rgba(31, 120, 114, 1)",
  fontWeight:"700",
  fontFamily:"Montserrat",
  fontSize:"1.2rem",
  marginBottom:"0.5rem",

}} >Contact</Typography>

<Typography style={{
  color:"rgba(31, 120, 114, 1)",
  fontFamily:"Montserrat",
  fontSize:"1rem",
  fontWeight:"500",
}} >Address</Typography>
<Typography style={{
    fontFamily:"Montserrat",
    fontWeight:"500",
   color:"rgba(95, 96, 96, 1)",
   marginTop:"0.5rem",
}} >70, Ratnajyot Industrial Estate, 2nd Floor, Irla Lane, Vile Parle (W), Mumbai, Maharashtra 400056</Typography>

<Typography style={{
  color:"rgba(31, 120, 114, 1)",
  fontFamily:"Montserrat",
  fontSize:"1rem",
  fontWeight:"500",
  marginTop:"0.5rem",

  color:"rgba(31, 120, 114, 1)",

}} >Mobile Number</Typography>
<Typography style={{
  fontFamily:"Montserrat",
  fontWeight:"500",

  marginTop:"0.5rem",
  color:"rgba(95, 96, 96, 1)",


}} >+91-9320838762</Typography>


    </Box>

    <Box sx={{
      width:'20rem',
    }} >
<Typography  style={{
  color:"rgba(31, 120, 114, 1)",
  fontWeight:"700",
  fontFamily:"Montserrat",
  fontSize:"1.2rem",
  marginBottom:"0.5rem",

}} >Download Amyraa App From</Typography>

<Box style={{
      display:"flex",
      width:"70%",
      justifyContent:"space-between",
      margin:"0 auto",
    }} >

    <Box style={{
      width:"6.2rem",
    }} >
    <a href="https://apps.apple.com/in/app/amyraa/id6476032395" target='_blank' >
    <img src={Appstorelogo} alt="" width="100%" />
    </a>
      
    </Box>

    <Box style={{
      width:"6.2rem",
    }} >
    <a href="https://play.google.com/store/apps/details?id=com.amyra" target='_blank' >
    <img src={PlayStore} alt="" width="100%" />

    </a>
    </Box>

    </Box>


    </Box>


    </Box>
    <Box sx={{
      height:"5%",
      margin:"0 auto",
      width:'90%',
    }} >
    <Typography style={{
      textAlign:"center",
      fontFamily:'Montserrat',
      fontWeight:"500",
      padding:'2rem',
    }} >Copyright 2023. Vivah Creations. All rights reserved.</Typography>

   

    </Box>

    

    </Box>
  )
}

export default FooterOne
