import { ActionTypes } from "../constants/action-type";

const initialState = {
  price: 123,
};
export const PriceCurrencyReducer = (
  state = initialState,
  { type, payload }
) => {
  // console.log("Currency_payload", type, payload);
  switch (type) {
    case ActionTypes.CURRENCY_CONVERTER:
      return { ...state, price: payload };
    default:
      return state;
  }
};
