import PropTypes from "prop-types";
import React, { useEffect, Suspense, useState, lazy } from "react";
import Alert from "./utils/Alert";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { useMediaQuery } from "react-responsive";
import Modal from "react-bootstrap/Modal";

import "react-lazy-load-image-component/src/effects/blur.css";
import Home from "./pages/home/Home";

import Product from "./pages/shop-product/ProductDetail";
import Account from "./pages/account/Account";
import ProductListing from "./pages/productListing/ProductListing";

import ProductSearchListing from "./pages/productSearchListing/ProductListing";
import OrderDetails from "./pages/MyOrders/Order_detail";
import BillDeskSdk from "./pages/paymentGateway/BillDeskSdk";
import DeleteAccountPage from "./components/header/DeleteAccountPage.js";
const FactoryOutlet = lazy(() =>
  import("./pages/FactoryOutlet/FactoryOutlet.jsx")
);
const Collection = lazy(() => import("./pages/collection/Collection"));
const ViewAllPage = lazy(() => import("./pages/viewAll/ViewAll.jsx"));
const MyOrders = lazy(() => import("./pages/MyOrders/Myorders"));
// other pages
const About = lazy(() => import("./pages/other/About"));
const reset = lazy(() => import("./pages/login/Forgot"));

const Contact = lazy(() => import("./pages/other/Contact"));
// const Login = lazy(() => import("./pages/Login/login"));

const Cart = lazy(() => import("./pages/cart/Cart"));
const NewHomePage = lazy(() => import("./pages/home/NewHomePage.jsx"));

const Wishlist = lazy(() => import("./pages/wishlist/Wishlist"));
const Checkout = lazy(() => import("./pages/checkout/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const Registration = lazy(() => import("./components/login/registration"));
const LoginRegister = lazy(() => import("./pages/login/LoginRegister"));
const ChangePassword = lazy(() => import("./pages/login/changepassword"));

const FAQ = lazy(() => import("./pages/other/FAQ.js"));
const Shipping = lazy(() => import("./pages/other/Shipping"));
const Return_Refund_Policy = lazy(() =>
  import("./pages/other/Return_Refund_Policy")
);

const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const Terms_and_Conditions = lazy(() =>
  import("./pages/other/Terms_and_Conditions")
);
// const OrderDetails = lazy(() => import("./pages/myOrders/Order_detail"));
const StoreDetails = lazy(() => import("./pages/MyOrders/StoreDetails"));
const BlogListings = lazy(() => import("./pages/blogs/BlogListings.jsx"));
const BlogDetails = lazy(() => import("./pages/blogs/BlogDetail.jsx"));
const BlogDetails2 = lazy(() => import("./pages/blogs/BlogDetail2.jsx"));

const App = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const [shareCollection, setShareCollection] = useState(true);
  const handleOpenshareCollection = () => setShareCollection(true);
  const handleCloseshareCollection = () => setShareCollection(false);
  // useEffect(() => {
  //   if(!shareCollection){
  //     handleOpenshareCollection();

  //   }
  // });
  const RedirectToApp = () => {
    return (
      <Modal
        show={shareCollection}
        onHide={handleCloseshareCollection}
        dialogClassName="my-modal"
        className="border-round"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <h4>Please switch to App For Better experience</h4>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    console.log = () => {};
  }, []);

  return (
    <ToastProvider placement="top-right">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              {/* {isTabletOrMobile ? <RedirectToApp /> : null} */}
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={NewHomePage}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/home"}
                  component={NewHomePage}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/account"}
                  component={Account}
                />
                {/* Homepages */}
                {/* Shop pages */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/products"}
                  component={ProductListing}
                />
                {/* Shop product pages */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/product/:BASEDESIGN/:DCODE"}
                  component={Product}
                />
                {/* View All Page */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/viewAll/:fetchKey"}
                  component={ViewAllPage}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/factory-outlet/view-all"}
                  component={FactoryOutlet}
                />
                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                {/* Delete Pages  */}
                //
                <Route
                  path={process.env.PUBLIC_URL + "/deleteAccountPage"}
                  component={DeleteAccountPage}
                />
                //
                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={LoginRegister}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/register"}
                  component={LoginRegister}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/changepassword/:mobno"}
                  component={ChangePassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Collection"}
                  component={Collection}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                {/* <Route
                  path={process.env.PUBLIC_URL + "/register-user"}
                  component={Registration}
                /> */}
                {/* <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                /> */}
                <Route
                  path={process.env.PUBLIC_URL + "/reset"}
                  component={reset}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Privacy_Policy"}
                  component={PrivacyPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Return_Refund_Policy"}
                  component={Return_Refund_Policy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Shipping"}
                  component={Shipping}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Terms_and_Conditions"}
                  component={Terms_and_Conditions}
                />
                <Route path={process.env.PUBLIC_URL + "/FAQ"} component={FAQ} />
                <Route
                  path={process.env.PUBLIC_URL + "/bloglisting"}
                  component={BlogListings}
                />
                {/* <Route
                  path={process.env.PUBLIC_URL + "/blogdetail/:id"}
                  component={BlogDetails}
                /> */}
                <Route
                  path={process.env.PUBLIC_URL + "/blogdetail"}
                  component={BlogDetails}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blogdetail2"}
                  component={BlogDetails2}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/MyOrders"}
                  component={MyOrders}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Order-Details/:id"}
                  component={OrderDetails}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/searchResult"}
                  component={ProductSearchListing}
                />
                <Route
                  path={
                    process.env.PUBLIC_URL + "/Store-Details/:BILLNO/:CUSTID"
                  }
                  component={StoreDetails}
                />
                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/payment/billDesk/:merchId/:bdOrderId/:authToken"
                  }
                  component={BillDeskSdk}
                />
                <Route exact component={NotFound} />
              </Switch>
              <Alert />
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

export default App;
