export const ActionTypes = {
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_PRODUCT: "SET_PRODUCT",
  SELECTED_FILTERS: "SELECTED_FILTERS",
  SELECTED_TOP_FILTERS: "SELECTED_TOP_FILTERS",
  SEARCH: "SEARCH",
  SET_USER: "SET_USER",
  ADD_TO_CART: "ADD_TO_CART",
  UPDATE_CART: "UPDATE_CART",
  DELETE_FROM_CART: "DELETE_FROM_CART",
  DELETE_ALL_FROM_CART: "DELETE_ALL_FROM_CART",
  SET_USERINFORMATION: "SET_USERINFORMATION",
  SELECTED_CATEGORY: "SELECTED_CATEGORY",
  GET_WISHLIST: "GET_WISHLIST",
  CURRENCY_CONVERTER: "CURRENCY_CONVERTER",
  HINDILANGUAGE: "HINDILANGUAGE",
  ENGLISHLANGUAGE: "ENGLISHLANGUAGE",
  SPANISHLANGUAGE: "SPANISHLANGUAGE",
  TAMILLANGUAGE: "TAMILLANGUAGE",
  TELUGULANGUAGE: "TELUGULANGUAGE",
  FRENCHLANGUAGE: "FRENCHLANGUAGE",
  SELECT_ALL: "SELECT_ALL",
  IS_SELECT_ALL: "IS_SELECT_ALL",

  // view all
  SET_INITIAL_FILTERS: "SET_INITIAL_FILTERS",
  SET_SELECTED_PRODUCTS: "SET_SELECTED_PRODUCTS",
  UPDATE_FILTERS_KEY_VALUE: "UPDATE_FILTERS_KEY_VALUE",
  APPLY_VIEW_ALL_FILTERS: "APPLY_VIEW_ALL_FILTERS",
  RESET_VIEW_ALL_FILTERS: "RESET_VIEW_ALL_FILTERS",
};
