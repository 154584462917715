// import { createStore } from "redux";
// import { reducers } from "./Reducer/index";
// // import { persistStore, persistReducer } from 'redux-persist'
// // import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// const store = createStore(
//   reducers,
//   {},
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// export default store;

import { createStore } from "redux";
import { reducers } from "./Reducer/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// const store = createStore(
//   reducers,
//   {},
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["allProducts", "selectAll", "viewAllFilters"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

let store = createStore(persistedReducer);
let persistor = persistStore(store);
export { store, persistor };

// export default store;
